import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseDto } from 'app/shared/models/response-dto.model';
import { CacheService } from 'app/shared/services/cache.service';
import { getDecodedAccessToken } from 'app/shared/utils/jwt';
import { catchError, map, mergeMap, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { ILoginResponse } from './models/login-response.model';
import { IUser, User } from './models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated = false;
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly cacheService = inject(CacheService);

  set refreshToken(token: string) {
    this.cacheService.storeCookie('refreshToken', token);
  }

  set accessToken(token: string) {
    this.cacheService.storeCookie('accessToken', token);
  }

  get refreshToken(): string {
    return this.cacheService.getCookie('refreshToken') ?? '';
  }

  get accessToken(): string {
    return this.cacheService.getCookie('accessToken') ?? '';
  }

  set user(value: IUser) {
    this._user.next(value);
  }

  get user$(): Observable<IUser> {
    return this._user.asObservable();
  }

  signIn(credentials: { email: string; password: string }): Observable<boolean> {
    return this.http.post('/management-auth/login', credentials).pipe(
      tap(({ data }: ResponseDto<ILoginResponse>) => {
        const decodedAccessToken = getDecodedAccessToken(data.accessToken);
        const { digital_platform_role } = decodedAccessToken;
        if(digital_platform_role==='operator' || digital_platform_role==='operatorManager'){
          this.accessToken = "";
          this.refreshToken = "";
          return;
        }
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
        this._authenticated = true;
      }),
      switchMap(() => this.getCurrentUser())
    );
  }

  resetPasswordRequest(email: string): Observable<void> {
    return this.http.post<void>('/management-auth/password-reset', { email });
  }

  resetPassword(token: string, newPassword: string): Observable<void> {
    return this.http.put<void>('/management-auth/password-reset', { token, newPassword });
  }

  getCurrentUser(): Observable<boolean> {
    const decodedAccessToken = getDecodedAccessToken(this.accessToken);
    const { digital_platform_id } = decodedAccessToken;
    
    return this.http.get(`/management-staff/${digital_platform_id}`).pipe(
      catchError(() => of(false)),
      switchMap((response: ResponseDto<IUser>) => {
        this._authenticated = true;

        this._user.next(User.Build(response.data));

        return of(true);
      })
    );
  }

  signOut(): Observable<boolean> {
    return this.http
      .post<void>('/management-auth/logout', {
        refreshToken: this.refreshToken,
      })
      .pipe(
        tap(() => {
          this.clearAuthData();
        }),
        map(() => true)
      );
  }

  checkUserSession(): Observable<boolean> {
    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    return this.getCurrentUser();
  }

  refresh(): Observable<void> {
    return this.http
      .post('/management-auth/login', {
        refreshToken: this.refreshToken,
      })
      .pipe(
        tap(({ data }: ResponseDto<ILoginResponse>) => {
          this.accessToken = data.accessToken;
          this.refreshToken = data.refreshToken;

          this._authenticated = true;
        }),
        mergeMap(() => this.getCurrentUser()),
        catchError((error) => {
          this.clearAuthData();

          this.router.navigate(['/']);

          return of(error);
        })
      );
  }

  updateUser(user: IUser): Observable<any> {
    return this.http.patch<IUser>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(User.Build(response));
      })
    );
  }

  private clearAuthData(): void {
    this.cacheService.deleteCookie('accessToken');
    this.cacheService.deleteCookie('refreshToken');

    this._authenticated = false;
  }
}
