<div class="flex flex-wrap items-center font-medium">
  <div class="flex items-center" *ngFor="let breadCrumb of breadCrumbs$ | async; let last = last">
    <a
      class="whitespace-nowrap text-primary-500 flex items-center"
      [ngClass]="{
        'pointer-events-none': !breadCrumb.active
      }"
      [routerLink]="breadCrumb.url"
    >
      <mat-icon [svgIcon]="breadCrumb.icon" class="mr-3 text-black" *ngIf="breadCrumb.icon"></mat-icon>
      <span
        [ngClass]="{
          'text-black': breadCrumb.active,
          'text-gray-500': !breadCrumb.active
        }"
        >{{ breadCrumb.label | transloco }}</span
      >
    </a>

    <mat-icon
      svgIcon="heroicons_outline:chevron-right"
      class="mx-3 text-black breadcrumb-chevron-icon"
      *ngIf="!last"
    ></mat-icon>
  </div>
</div>
