import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { LuxonDateAdapter } from "@angular/material-luxon-adapter";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
    PreloadAllModules,
    provideRouter,
    withComponentInputBinding,
    withInMemoryScrolling,
    withPreloading
} from "@angular/router";
import { provideFuse } from "@fuse";
import { appRoutes } from "app/app.routes";
import { provideIcons } from "app/core/icons/icons.provider";
import { provideTransloco } from "app/core/transloco/transloco.provider";
import { mockApiServices } from "app/mock-api";
import { AuthInterceptor } from "./core/auth/auth.interceptor";
import { CustomMatPaginatorIntl } from "./shared/utils/custom-paginator-intl";

import { registerLocaleData } from "@angular/common";
import localeItIt from "@angular/common/locales/it";
import { TranslocoService } from "@ngneat/transloco";
import { provideQuillConfig } from "ngx-quill";

registerLocaleData(localeItIt);

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(MatSnackBarModule),
        provideQuillConfig({
            modules: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],        // toggled buttons
                    [{ "header": 1 }, { "header": 2 }],               // custom button values
                    [{ "list": "ordered" }, { "list": "bullet" }],                       // text direction
                    [{ "color": [] }, { "background": [] }],          // dropdown with defaults from theme
                    [{ "align": [] }]
                ]
            }
        }),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: "enabled" }),
            withComponentInputBinding()
        ),
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter
        },
        {
            provide: LOCALE_ID,
            useFactory: (translocoService) => translocoService.getActiveLang(),
            deps: [TranslocoService]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: "D"
                },
                display: {
                    dateInput: "DDD",
                    monthYearLabel: "LLL yyyy",
                    dateA11yLabel: "DD",
                    monthYearA11yLabel: "LLLL yyyy"
                }
            }
        },

        // Transloco Config
        provideTransloco(),

        // Fuse
        // provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices
            },
            fuse: {
                layout: "futuristic",
                scheme: "light",
                screens: {
                    sm: "600px",
                    md: "960px",
                    lg: "1280px",
                    xl: "1440px"
                },
                theme: "theme-default",
                themes: [
                    {
                        id: "theme-default",
                        name: "Default"
                    }
                ]
            }
        })
    ]
};
