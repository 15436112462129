import { Role } from 'app/core/auth/enums/role.enum';
import { IUser } from 'app/core/auth/models/user.model';
import { Language } from 'app/shared/enums/language.enum';

/* eslint-disable */
export const user: IUser = {
  _id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
  firstName: 'Brian Hughes',
  email: 'hughes.brian@company.com',
  role: Role.SUPERADMIN,
  avatar: 'assets/images/avatars/brian-hughes.jpg',
  status: 'online',
  language: Language.IT
};
