import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { ResponseDto } from 'app/shared/models/response-dto.model';
import { CacheService } from 'app/shared/services/cache.service';
import { Observable, map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private readonly http = inject(HttpClient);
  private readonly cacheService = inject(CacheService);

  getTranslation(lang: string): Observable<Translation> {
    let headers = new HttpHeaders();

    const etagKey = `etag-label-${lang}`;
    const labelsKey = `label-${lang}`;

    const etag = this.cacheService.getItem(etagKey);

    if (etag) {
      headers = headers.set('If-None-Match', etag);
    }

    return this.http
      .get<ResponseDto<Translation>>(`/labels/backoffice/${lang}`, {
        headers,
        observe: 'response',
      })
      .pipe(
        tap((response) => {
          if (response.status === 200) {
            const newEtag = response.headers.get('etag');

            this.cacheService.setItem(labelsKey, JSON.stringify(response.body?.data));

            if (newEtag) {
              this.cacheService.setItem(etagKey, newEtag);
            }
          }
        }),
        map(() => {
          const labels = JSON.parse(this.cacheService.getItem(labelsKey));

          return {
            // Add dev translations here
            ...labels,
          };
        })
      );
  }
}
