export enum Role {
  SUPERADMIN = 'superAdmin',
  ADMIN = 'admin',
  SERVICE_ADMIN = 'serviceAdmin',
  MARKETING_OPERATOR = 'marketingOperator',
  STORE_MANAGER = 'storeManager',
  OPERATOR = 'operator',
  OPERATOR_MANAGER = 'operatorManager',
}

export const ROLE_OPTIONS = Object.values(Role).map((role) => ({
  value: role,
  label: `users.roles.${role}`,
}));
