import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private readonly cookieService = inject(CookieService);

  public getItem(key: string, storage: Storage = localStorage): string {
    return storage.getItem(key);
  }

  public setItem(key: string, value: string, storage: Storage = localStorage) {
    return storage.setItem(key, value);
  }

  public storeCookie(key: string, value: string, path = '/') {
    return this.cookieService.set(key, value, null, path);
  }

  public getCookie(key: string) {
    return this.cookieService.get(key);
  }

  public deleteCookie(key: string, path = '/') {
    return this.cookieService.delete(key, path);
  }
}
