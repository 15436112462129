import { Injectable, inject } from '@angular/core';
import { AuthService } from '../auth.service';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  private readonly authService = inject(AuthService);

  public user$ = this.authService.user$;

  resetPasswordRequest(email: string) {
    return this.authService.resetPasswordRequest(email);
  }

  resetPassword(token: string, newPassword: string) {
    return this.authService.resetPassword(token, newPassword);
  }
}
