import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';
import { AuthFacade } from './core/auth/facades/auth.facade';
import { ALLOWED_LANGUAGES, Language } from './shared/enums/language.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly translocoService = inject(TranslocoService);
  private readonly authFacade = inject(AuthFacade);

  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.setDefaultLanguage();

    this.authFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.translocoService.setActiveLang(user.language);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * If the user is not logged then set the language to the browser's one,
   * if it's not valid then use the fallback it-IT
   */
  private setDefaultLanguage(): void {
    const browserLanguage = navigator.language as Language;

    if (!ALLOWED_LANGUAGES.includes(browserLanguage)) {
      this.translocoService.setActiveLang(Language.IT);
    } else {
      this.translocoService.setActiveLang(browserLanguage);
    }
  }
}
