export interface IStats {
  count: number;
}

export class Stats {
  protected constructor(public count: number) {}

  public static Build(stats: IStats): Stats {
    return new this(stats.count);
  }
}
