import { Injectable, inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  private ofLabel = 'paginator.of';

  private readonly translateService = inject(TranslocoService);

  constructor() {
    super();

    this.translateService.selectTranslation().subscribe(() => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.itemsPerPageLabel = this.translateService.translate('paginator.items-per-page');
    this.firstPageLabel = this.translateService.translate('paginator.pages.first');
    this.lastPageLabel = this.translateService.translate('paginator.pages.last');
    this.nextPageLabel = this.translateService.translate('paginator.pages.next');
    this.previousPageLabel = this.translateService.translate('paginator.pages.prev');

    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const ofLabel = this.translateService.translate(this.ofLabel);

    if (length === 0 || pageSize === 0) {
      return `0 ${ofLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${ofLabel} ${length}`;
  };
}
