import { Role } from 'app/core/auth/enums/role.enum';
import { StationType } from 'app/modules/stations/enums/management-station-type.enum';
import { Language } from 'app/shared/enums/language.enum';

export interface IUser {
  _id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  role: Role;
  language: Language;
  avatar?: string;
  status?: string;
  stationIds?: string[];
  services?: StationType[];
}

export class User {
  protected constructor(
    public _id: string,
    public email: string,
    public role: Role,
    public language: Language,
    public firstName?: string,
    public lastName?: string,
    public avatar?: string,
    public status?: string,
    public stationIds?: string[],
    public services?: StationType[]
  ) {}

  public static Build(user: IUser): User {
    return new this(
      user._id,
      user.email,
      user.role,
      user.language,
      user.firstName,
      user.lastName,
      user.avatar,
      user.status,
      user.stationIds,
      user.services
    );
  }
}
