export enum Language {
  IT = 'it-IT',
  EN = 'en-US',
}

export const LANGUAGE_OPTIONS = Object.values(Language).map((language) => ({
  value: language,
  label: `common.languages.${language}`,
  flag: `assets/images/flags/${language}.svg`,
}));

export const ALLOWED_LANGUAGES = Object.values(Language);
