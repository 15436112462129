import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { map, of, take, tap } from 'rxjs';
import { AuthFacade } from '../facades/auth.facade';

export const RoleGuard: CanActivateFn | CanActivateChildFn = (route) => {
  const router = inject(Router);

  const { roles } = route.data;

  return inject(AuthFacade).user$.pipe(
    take(1),
    map((user) => !roles || roles.includes(user.role)),
    tap((hasAccess: boolean) => {
      if (!hasAccess) {
        router.navigate(['/home']);

        return of(false);
      }
    })
  );
};
