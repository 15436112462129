<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-white text-black print:hidden shadow"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationHeader>
    <!-- Logo -->
    <div class="flex justify-center p-6 pb-10">
      <img class="w-24" src="assets/images/logo/vega-logo.svg" />
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <!-- <ng-container fuseVerticalNavigationFooter>
    <div class="flex items-center w-full px-6 py-8 border-t">
      <user></user>
      <div class="flex flex-col w-full ml-4 overflow-hidden">
        <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal text-current opacity-80">
          {{ user.name }}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50"
        >
          brian.hughes@company.com
        </div>
      </div>
    </div>
  </ng-container> -->
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Navigation toggle button -->
    <button class="mr-5" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>

    <breadcrumb></breadcrumb>

    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2 mr-7">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block" [tooltip]="'common.actions.fullscreen' | transloco"></fuse-fullscreen>
      <!-- <notifications></notifications> -->
    </div>

    <user></user>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto p-5">
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <div
    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary">Fuse &copy; {{ currentYear }}</span>
  </div> -->
</div>
