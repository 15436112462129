export interface IManagementUser {
  _id: string;
  userId: string;
  numberOfDevices: number;
  numberOfCCs: number;
  numberOfVegaCards: number;
  firstname?: string;
  lastname?: string;
  lastAccessAt?: string;
}

export class ManagementUser {
  constructor(
    public _id: string,
    public userId: string,
    public numberOfDevices: number,
    public numberOfCCs: number,
    public numberOfVegaCards: number,
    public firstname?: string,
    public lastname?: string,
    public lastAccessAt?: Date
  ) {}

  public static Build(managementUser: IManagementUser): ManagementUser {
    const lastAccessAt = managementUser.lastAccessAt ? new Date(managementUser.lastAccessAt) : undefined;

    return new this(
      managementUser._id,
      managementUser.userId,
      managementUser.numberOfDevices,
      managementUser.numberOfCCs,
      managementUser.numberOfVegaCards,
      managementUser.firstname,
      managementUser.lastname,
      lastAccessAt
    );
  }
}
