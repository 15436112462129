import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { IBreadcrumb } from 'app/shared/models/breadcrumb.model';
import { UiService } from 'app/shared/services/ui.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  imports: [AsyncPipe, TranslocoModule, NgForOf, NgIf, NgClass, MatIconModule, RouterModule],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public breadCrumbs$: Observable<IBreadcrumb[]>;

  private readonly destroy$ = new Subject<void>();

  private readonly uiService = inject(UiService);

  ngOnInit() {
    this.breadCrumbs$ = this.uiService.breadCrumbs$;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
