import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBreadcrumb } from '../models/breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  private _pageTitle$ = new BehaviorSubject<string>('');
  private _breadCrumbs$ = new BehaviorSubject<IBreadcrumb[]>([this.dashboardBreadcrumb()]);

  public set pageTitle(title: string) {
    this._pageTitle$.next(title);
  }

  public get pageTitle$(): Observable<string> {
    return this._pageTitle$.asObservable();
  }

  public set breadCrumbs(breadCrumbs: IBreadcrumb[]) {
    this._breadCrumbs$.next([this.dashboardBreadcrumb(), ...breadCrumbs]);
  }

  public get breadCrumbs$(): Observable<IBreadcrumb[]> {
    return this._breadCrumbs$.asObservable();
  }

  private dashboardBreadcrumb(): IBreadcrumb {
    return {
      // icon: 'heroicons_outline:home',
      label: 'menu.navigation.labels.home',
      url: '/',
      active: true,
    };
  }
}
