import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, catchError, filter, mergeMap, take, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly authService = inject(AuthService);
  private readonly snackbarService = inject(MatSnackBar);
  private readonly translocoService = inject(TranslocoService);

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const options = {
      url: req.url,
      setHeaders: {
        'Accept-Language': this.translocoService.getActiveLang(),
      },
    };

    req = this.addToken(req, this.authService.accessToken);

    if (!req.url.includes('assets')) {
      options.url = `${environment.apiUrl}${req.url}`;
    }

    const newReq = req.clone(options);

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            if (this.authService.refreshToken && !newReq.url.includes('login')) {
              return this.handle401Error(newReq, next);
            }

            if (!newReq.url.includes('login')) {
              this.authService.signOut();
              location.reload();
            }
          } else {
            const message = error.error.message;

            this.snackbarService.open(this.translocoService.translate(`server.errors.${message}`), null, {
              duration: 2500,
              panelClass: 'error-snackbar',
            });
          }
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refresh().pipe(
        mergeMap(() => {
          const token = this.authService.accessToken;

          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);

          return next.handle(this.addToken(request, token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        mergeMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      ...(this.authService.accessToken &&
        !request.url.includes('labels') &&
        !request.url.includes('login') &&
        !request.url.includes('assets') && {
          setHeaders: {
            'X-Credential-Identifier': 'admin',
            authorization: `Bearer ${token}`,
          },
        }),
    });
  }
}
