import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthFacade } from 'app/core/auth/facades/auth.facade';
import { IUser } from 'app/core/auth/models/user.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, TranslocoModule],
})
export class UserComponent implements OnInit, OnDestroy {
  static ngAcceptInputType_showAvatar: BooleanInput;

  @Input() showAvatar: boolean = true;

  public user: IUser;

  private readonly destroy$: Subject<void> = new Subject<void>();

  private readonly cd = inject(ChangeDetectorRef);
  private readonly router = inject(Router);
  private readonly authFacade = inject(AuthFacade);
  private readonly authService = inject(AuthService);

  ngOnInit(): void {
    this.authFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((user: IUser) => {
      this.user = user;

      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this.authService
      .updateUser({
        ...this.user,
        status,
      })
      .subscribe();
  }

  signOut(): void {
    this.authService
      .signOut()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.navigate(['/sign-in']);
      });
  }
}
