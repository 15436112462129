import { Directive, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { User } from 'app/core/auth/models/user.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthFacade } from '../facades/auth.facade';
import { Role } from './../enums/role.enum';

@Directive({
  selector: '[authRoles]',
  standalone: true,
})
export class RoleDirective implements OnInit, OnDestroy {
  @Input() authRolesAction: 'class' | 'hide' | 'attribute' = 'hide';
  @Input() authRolesClassNames = ['disabled'];
  @Input() authRolesAttribute: { key: string; value: string } = { key: 'disabled', value: 'true' };

  @Input() set authRoles(roles: Role[]) {
    this.roles = roles;

    this.checkPermission();
  }

  private roles!: Role[];
  private user?: User;

  private readonly destroy$ = new Subject<void>();

  private readonly templateRef = inject(TemplateRef<unknown>);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly renderer = inject(Renderer2);
  private readonly authFacade = inject(AuthFacade);

  public ngOnInit(): void {
    this.authFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;

      this.checkPermission();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkPermission(): void {
    if (!this.roles || this.roles.includes(this.user?.role)) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.executeActionOnComponent();
    }
  }

  private executeActionOnComponent(): void {
    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);

    switch (this.authRolesAction) {
      case 'class':
        this.authRolesClassNames.forEach((c) => this.renderer.addClass(view.rootNodes[0], c));
        break;
      case 'attribute':
        this.renderer.setAttribute(view.rootNodes[0], this.authRolesAttribute.key, this.authRolesAttribute.value);
        break;
      case 'hide':
      default:
        this.viewContainerRef.clear();
        break;
    }
  }
}
