import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';

@Injectable({ providedIn: 'root' })
export class AuthMockApi {
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {}
}
